import React, {useState} from 'react';
import "./GetLanguage.css";

import {Configuration, OpenAIApi} from 'openai';

const configuration = new Configuration ({
  apiKey: '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',
});
const openai = new OpenAIApi (configuration);

const GetLanguage = (props) => {
    const [otherLanguage, setOtherLanguage] = useState ('');
    const [response, setResponse] = useState (null);
    const [apiCalled, setApiCalled] = useState (false);
  
    const callOpenAIapi = async () => {
      if((response === null) && (apiCalled === false)){
        setApiCalled(true);
        let completionData = await openai.createCompletion ({
          model: 'text-davinci-003',
          prompt: `answer in yes or no. is ${otherLanguage} a valid programming language`,
          max_tokens: 1024,
          n: 1,
          temperature: 0.5,
        });
        setResponse (completionData.data.choices[0].text);
        
        if(response.toLowerCase().includes("yes")) return true;
        else return false;
      }
    }


    function handleChange(event) {
        setOtherLanguage(event.target.value);
    }
    async function onSubmit() {
        let isValidLang = await callOpenAIapi();
        if(isValidLang) props.actionProvider.handleSelectLanguage();
        else props.actionProvider.selectLanguage();
    }

  return (
    <div className="options-container">
        <input type="text" value={otherLanguage} onChange={handleChange} placeholder="Enter language here" />
        <button onClick={onSubmit}>Submit</button>
        <button onClick={() => {
            props.actionProvider.selectLanguage();
        }}>Skip</button>
    </div>
  );
};

export default GetLanguage;
