import React from 'react';
import ReactDOM from 'react-dom/client';
import Latex from "./Latex.js"
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
const root = ReactDOM.createRoot( document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <Latex> <App /></Latex>,
   {/* </React.StrictMode>, */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
