import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";

import SelectLanguage from "./components/SelectLanguage/SelectLanguage";
import SelectUserType from "./components/SelectUserType/SelectUserType";
import SelectTopic from "./components/SelectTopic/SelectTopic";
import GetAnswer from "./components/GetAnswer/GetAnswer";
import RestartChat from "./components/RestartChat/RestartChat";
import SelectTime from "./components/SelectTime/SelectTime";
import GetEmail from "./components/GetEmail/GetEmail";
import GetLanguage from "./components/GetLanguage/GetLanguage";

const config = {
  botName: "LearningBot",
  initialMessages: [
    createChatBotMessage(`Hello! We teach coding to beginners. What do you want to learn today?`, {
      widget: "selectLanguage",
    }),
  ],
  state: {
    languageSelected: '',
    userTypeSelected: '',
    userTone: '',
    topicSelected: '',
  },
  widgets: [
    {
      widgetName: "selectLanguage",
      widgetFunc: (props) => <SelectLanguage {...props} />,
    },
    {
      widgetName: "getLanguage",
      widgetFunc: (props) => <GetLanguage {...props} />,
    },
    {
        widgetName: "selectUserType",
        widgetFunc: (props) => <SelectUserType {...props} />,
        mapStateToProps: ['languageSelected'],
    },
    {
      widgetName: "selectTime",
      widgetFunc: (props) => <SelectTime {...props} />,
    },
    {
      widgetName: "getEmail",
      widgetFunc: (props) => <GetEmail {...props} />,
    },
    {
        widgetName: "selectTopic",
        widgetFunc: (props) => <SelectTopic {...props} />,
        mapStateToProps: ['languageSelected', 'userTypeSelected'],
    },
    {
        widgetName: "getAnswer",
        widgetFunc: (props) => <GetAnswer {...props} />,
        mapStateToProps: ['languageSelected', 'userTypeSelected', 'topicSelected', 'userTone'],
    },
    {
        widgetName: "restartChat",
        widgetFunc: (props) => <RestartChat {...props} />,
    },
  ],
};

export default config;
