import React from "react";

import "./SelectUserType.css";

const SelectUserType = (props) => {
  const options = [
    { 
      text: "just for fun",
      tone: "fun, humorous, gamified and interactive way",
      id: 1 
    },
    { 
      text: "school",
      tone: "friendly beginner way",
      id: 2
    },
    {
      text: "job opportunities",
      tone: "career oriented way",
      id: 3,
    },
  ];

  const buttonsMarkup = options.map((option) => (
    <button 
      key={option.id} 
      onClick={ () => {
        props.setState (prevState => ({
          ...prevState,
          userTypeSelected: option.text,
          userTone: option.tone,
        }));
        props.actionProvider.handleSelectUserType()}
      }
      className="option-button"
    >
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default SelectUserType;
