import React, {useState} from 'react';

import "./GetEmail.css";

const GetEmail = (props) => {
    const [userEmail, setUserEmail] = useState ('');
    function handleChange(event) {
        setUserEmail(event.target.value);
    }

  return (
    <div className="options-container">
        <input type="text" value={userEmail} onChange={handleChange} placeholder="Enter your email" />
        <button onClick={()=> {
            props.actionProvider.handleGetEmail(userEmail);
        }}>Submit</button>
        <button onClick={()=>{
            props.actionProvider.handleGetEmail(userEmail);
        }}>Skip</button>
    </div>
  );
};

export default GetEmail;
