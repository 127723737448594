import React from "react";

import "./SelectTime.css";

const SelectTime = (props) => {
  const options = [
    { 
      text: "Casual - 5 mins per day",
      id: 1 
    },
    { 
      text: "Regular - 10 mins per day",
      id: 2
    },
    {
      text: "Serious - 15 mins per day",
      id: 3,
    },
  ];

  const buttonsMarkup = options.map((option) => (
    <button 
      key={option.id} 
      onClick={ () => {
        props.actionProvider.handleSelectTime()}
      }
      className="option-button"
    >
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default SelectTime;
