import React from 'react'
import "../css/banner.css"

const Banner = () => {
  return (
    <div className='header'>
        <h5 className='head-text'>🐾Use promocode to avail <span className='text'>Free</span> access to pro courses.</h5>
    </div>
  )
}

export default Banner