class ActionProvider {
  constructor (createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  selectLanguage = () => {
    const message = this.createChatBotMessage (
      'Please select a valid language you want to learn.',
      {
        widget: 'selectLanguage',
      }
    );

    this.addMessageToState (message);
  };

  handleSelectLanguage = () => {
    const message = this.createChatBotMessage (
      'Why you want to learn a language?',
      {
        widget: 'selectUserType',
      }
    );

    this.addMessageToState (message);
  };

  handleOtherLanguage = () => {
    const message = this.createChatBotMessage (
      'No worries. Please enter you desired language you want to learn here.',
      {
        widget: 'getLanguage',
      }
    );

    this.addMessageToState (message);
  };

  handleSelectUserType = () => {
    const message = this.createChatBotMessage (
      'Great. Now choose a daily goal.',
      {
        widget: 'selectTime',
      }
    );

    this.addMessageToState (message);
  };

  handleSelectTime = () => {
    const message = this.createChatBotMessage (
      'Get a daily reminder to meet your goal',
      {
        widget: 'getEmail',
      }
    );

    this.addMessageToState (message);
  };

  handleGetEmail = () => {
    const message = this.createChatBotMessage (
      'Ok! It time to start learning.',
      {
        widget: 'selectTopic',
      }
    );

    this.addMessageToState (message);
  }

  handleSelectUserTopic = () => {
    const message = this.createChatBotMessage (
      'Processing .. plz wait .. ',
      {
        widget: 'getAnswer',
      }
    );
    this.addMessageToState (message);
  };

  restartChatRequest = () => {
    const message = this.createChatBotMessage (
      'Hope this was helpful. Do you want to restart the chat?',
      {
        widget: 'restartChat',
      }
    );

    this.addMessageToState (message);
  }

  addMessageToState = message => {
    this.setState (prevState => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  };
}

export default ActionProvider;
