import React from 'react';
import "../css/playground.css";
import Section from './Section';

function Playground() {
  // let user = localStorage.getItem("Email").substring(0,localStorage.getItem("Email").indexOf('@'))

  return (
  <div class="big-container">
    <div>
    {/* <h1 className="text-center my-4" style={{fontFamily: "sans-serif", color:"azure",fontSize:"50px" }}>
      <div class="main">
        <span class="material-symbols-outlined">
          contacts 
        </span> Welcome Coder
        <br/>
      {<>Start playing with your coding skills</>}<span class="material-symbols-outlined">potted_plant</span>
      </div>
    </h1> */}
    <center>
      <Section
        title="Do you have an idea? Run the programme in the playground."
      />
    </center>
    <div className='editor'>
      <div className='editor--embed'>
        <iframe
            style={{ "boxShadow": "0 0 20px 0px grey" }}
            frameborder="0"
            width="900px"
            height="750px"
            src="https://trinket.io/embed/python3/dec984184b"
            title="code-editor"
          >
        </iframe>
      </div>
    </div>
  </div>
</div>
  )
}

export default Playground;
