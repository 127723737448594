/* eslint-disable no-useless-escape */
import React, { useState ,useEffect} from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { useLocation, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import "../css/idepage.css";
import Swal from "sweetalert2";
import axios from 'axios';
import amplitude from 'amplitude-js' 

export default function IdePage() {

  const amplitude_instance=amplitude.getInstance().init("c15208543b30b83cf2b7f88298d7df35");
  
  
  const location = useLocation();
  const [problems, setProblems] = useState([
    {
      title: "",
      description: ``,
      input: "",
      output: ``,
      sol: ``,
    }
  ]);

  const [q, setQ] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.state.ideType === "problem") {
      axios({
        method: "GET",
        withCredentials: true,
        url: "https://brainlox-python-api.herokuapp.com/python/idepage?ideType=problem&title="+location.state.title,
      }).then((res) => {
        setProblems(res.data);
      }).catch((err) => {
        console.log(err);
      });
    }
    else {
      axios({
        method: "GET",
        withCredentials: true,
        url: "https://brainlox-python-api.herokuapp.com/python/idepage?ideType=workshop&title="+location.state.title,
      }).then((res) => {
        setProblems(res.data);
      }).catch((err) => {
        console.log(err);
      })
    }
}, [location.state.ideType, location.state.title])

  // for horizontal steps list
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [viewSol, setViewSol] = useState(false);

  const totalSteps = () => {
    return problems.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleStep = (step) => () => {
    sogglesol();
    setActiveStep(step);
    setQ(step);
    amplitude.getInstance().logEvent("step buttons are clicked!");
  };

  const handleNext = () => {
    sogglesol();
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? problems.findIndex((_step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    if (allStepsCompleted()) {
      Swal.fire({
        icon: 'success',
        title: 'All steps completed - you&apos;re finished',
        showConfirmButton: false,
        timer: 2000
      });
      setQ(problems.length-1);
    }
    else
      setQ(newActiveStep);
    amplitude.getInstance().logEvent("next button is clicked!");
  };
  
   const navigate = useNavigate();
  

  const handleBack = () => {
    sogglesol();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setQ(activeStep-1);
    amplitude.getInstance().logEvent("previous button is clicked!");
  };


  const handleComplete = () => {
    sogglesol();
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
    amplitude.getInstance().logEvent("mark as done is clicked!");
  };

  const handleReset = () => {
    toggleSol();
    setActiveStep(0);
    setCompleted({});
    setQ(0);
    amplitude.getInstance().logEvent("reset button is clicked!");
  };

  const sogglesol = () =>{
    setViewSol(false);
  }
  
  const toggleSol = () => {
    if (!viewSol)
      Swal.fire({
        icon: 'question',
        title: 'Do you want to see the solution?',
        showCancelButton: true,
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          amplitude.getInstance().logEvent("tapped on yes on solution view");
          Swal.fire({
            position:'center',
            text:problems[q].sol,
            confirmButtonText:["OK"]
          }).then((res)=>{
            if(res.isConfirmed){
              
            }
          }).catch(Swal.noop)
          setViewSol(true);
        }
      })
    else
      setViewSol(false);
    amplitude.getInstance().logEvent("clicked on solution!");
  }
  let copy;
  const [openS, setOpenS] = useState(false);
  const handlecopy=()=>{
    amplitude.getInstance().logEvent("copy button is clicked!");
    navigator.clipboard.writeText(copy);
  }

  return (
    <div className="IdePage">
      <div className="col">
        <Snackbar
          open={openS}
          autoHideDuration={2000}
          onClose={() => {
            setOpenS(false);
          }}
          message="Code Copied"
        />
        <Box sx={{ width: '100%',paddingTop:'25px',marginBottom:'20px' }}>
          <Stepper nonLinear activeStep={activeStep}>
            {problems.map((p, index) => {          
              return (
                <Step key={p.title} completed={completed[index]}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          <div>
            {allStepsCompleted() ? (
              <React.Fragment>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button variant="outlined" onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
              ):(
              <React.Fragment>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 3 }}>
                  <div className="previous">
                    <button className='buttons' variant="outlined" onClick={() => {navigate(-1);
                    amplitude.getInstance().logEvent("back button is clicked!");
                    }} sx={{}} style={{}}>
                    Back
                    </button>
                      {activeStep===0?'':(
                        <button
                        variant="outlined"
                        color="success"
                        onClick={handleBack}
                        sx={{  }}
                        className="instruct-buttons"
                        disabled={activeStep === 0}
                      >
                      Previous 
                      </button>
                      )}
                  </div>
                  <Box sx={{ flex: '1 1 auto' }} />
                    <div className="previous">
                      <button variant="outlined" color="success" onClick={handleNext} sx={{}}
                      className="instruct-buttons"
                      >
                        Next
                      </button>
                    </div>
                    { !viewSol ?
                        <button className="instruct-buttons" onClick={toggleSol} type="button" data-toggle="modal" data-target="#exampleModalCenter">View Solution</button> 
                        : <button className="instruct-buttons"
                        onClick={toggleSol} type="button" data-toggle="modal" data-target="#exampleModalCenter">
                            Hide Solution
                        </button>
                      }
                    <div className="previous">
                      {activeStep !== problems.length &&
                        (completed[activeStep] ? (
                            <Typography
                              variant="caption"
                              sx={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                marginLeft: '10px',
                                border: '1px solid',
                                padding: '2px 2px 2px 2px',
                                borderRadius: '5px',
                                fontfamily:'sans-serif'
                              }}>
                            Step {activeStep + 1} already completed
                          </Typography>
                          ):(
                          <button variant="outlined" onClick={handleComplete} sx={{}} className="instruct-buttons">
                          {completedSteps() === totalSteps() - 1
                            ? 'Mark as Done'
                            : 'Mark as Done' }
                        </button>
                        ))}
                    </div>
                  </Box>
                </React.Fragment>
              )}
      </div>
      </Box>
<div className="container-ide">
  <div className="question-box">
      <div className="ide-main">
        <div className="Prob">
          <div className="tab_btn_cont">
            <h5 className="tab_btn">
            {problems[q].title}
            </h5>
          </div>
          
          <div className="about">
            <h6 className="probDescription">
                {" "}
                {problems[q].description.split(/\r?\n/).map((r) => (
                  <>
                    <div dangerouslySetInnerHTML={{__html:r}}></div>
                    <br />
                  </>
                ))}{" "}
            </h6>
            
            {problems[q].input.length > 0 ? (
              <h6>
                <h6 className="inpOut">INPUT:</h6>
                <h6 className="inpOutText">{problems[q].input.split(/\r?\n/).map((e, _i) => (
                  <>
                    {e}
                    <br></br>
                  </>
                ))}</h6>{" "}
              </h6>
            ) : null}
            {problems[q].output.length > 0 ? (
                  <>
                    <h6 className="inpOut">OUTPUT:</h6>
                    <h6 className="inpOutText">
                      {problems[q].output.split(/\r?\n/).map((r) => (
                  <>
                    {r}
                    <br />
                  </>
                ))}
                    </h6>{" "}
                  </>
            ) : null}

          </div>

          <div className="short">
          <div className="tab_btn_cnt">
            <h5 className="tab_btn">
              Solution
                {viewSol ?
                  <LockOpenTwoToneIcon /> :
                  <LockIcon />
                }
            </h5>
          </div>
        
          <div
            style={{
              textAlign: "left",
              fontSize: "1rem",
              marginLeft: "10px"
            }}
          >
            <h6>
              {
                viewSol ?
                  <pre
                    style={{
                      backgroundColor: "#dae5ea",
                      color: "#263238",
                      width: "100%",
                      minHeight: "25vh",
                      marginbottom:"20%"
                    }}
                  >
                    <div className="copy-text">
                      <span className="c-text">{copy=problems[q].sol}</span>
                      <button className="copy-button" onClick={handlecopy}><span class="material-symbols-outlined">
                      file_copy
                      </span>
                      </button>
                    </div>
                  </pre> :
                  <div className="hidden">
                    <div className="hideBox">
                      <VisibilityOffIcon style={{fontSize: "50px" }} /> 
                      <p className="inpOut"> The Solution is Hidden</p>
                    </div> 
                    <div className="hiddenSol" />
                  </div>
              }
            </h6>
          </div>
        </div>
      </div>
     </div> 
   </div>
    <div className="container">
        <div style={{ display: "flex", textAlign: "center", justifyContent: "center" }} className="ide">
          <iframe
            style={{ boxShadow: "0 0 20px 0px grey",height:"800px"}}
            frameborder="0"
            width="700px"
            height="700px"
            src="https://trinket.io/embed/python3/dec984184b"
            title="code-editor"
          >
          </iframe>
        </div>
      </div>
    </div>
  </div>
</div>
  );
}
