import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import "../css/customCard.css";

function CourseCard({title, difficulty,status,questions}) {
    return (
        <div className='card-container'>
            {questions===0?'':(
                 <Card className='card-l'>
                 <CardActionArea>
                     <CardMedia
                         component="img"
                         height="200px"
                         image={title==='Input/Output'?'/images/o.jpg':title==='Numbers'?'/images/numb.jpg':title==='Math'?'/images/math.png':title==='Strings'?'/images/string.jpg':title==='Conversions'?'/images/convert.png':title==='Data Structures'?'/images/data.jpg':title==='Searching and Sorting'?'/images/nets.jpg':title==='Games'?'/images/trials.jpg':title==='Network/OS'?'/images/networkos.png':title==='Trial'?'/images/games.png':title==='Calculator Workshop'?'/images/calculator.png':title==='Number guess Game'?'/images/que.png':title==='Library Management'?'/images/library.jpg':'/images/pythonBg.jpg'}
                         alt=""
                     />
                     <div class="ribbon">
                        {status==="Locked"?
                            <span class="ribbon__content">{'Pro'}</span>
                            :<span class="ribbon__content2">{'Basic'}</span>
                        }
                     </div>
                     <CardContent className={status==='Unlocked'?'flex-course-un':'flex-course-n'}>
                         <div class="course-flex">
                            <Typography gutterBottom noWrap={true}variant="body1" component="div">
                                {title.replace("Questions","Program")}
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary">
                            </Typography> 
                         </div>
                         <div className='cardDetails'>
                             <Typography variant="subtitle1" color="text.secondary">
                                {difficulty}
                             </Typography>
                             <Typography variant='subtitlel' color="text.secondary">
                                {`${questions} programs`}
                            </Typography>     
                         </div>    
                     </CardContent>
                 </CardActionArea>
             </Card>
            )}
           
        </div>
        
    )
}

export default CourseCard;
