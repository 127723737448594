import React,{ useRef,useState } from 'react'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import "../css/contact.css";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const MySwal = withReactContent(Swal)
const alertContent = () => {
  MySwal.fire({
    title: 'Congratulations!',
    text: 'Your message was successfully send and will back to you soon',
    icon: 'success',
    timer: 1000,
    timerProgressBar: true,
    showConfirmButton: false
  })
}

// Form initial state
const INITIAL_STATE={
    name:'',
    email:'',
    message:'',
}

const Contact = () => {
  const [contact, setContact] = useState(INITIAL_STATE)
  const { handleSubmit } = useForm()
  const form =useRef();
  const navigate=useNavigate();

  const handleChange = e => {
    const { name, value } = e.target
    setContact(prevState => ({ ...prevState, [name]: value }))
  }

  const onSubmit =async(e) => {
    // e.preventdefault();
    console.log(contact);
    await axios({
        method: "GET",
        withCredentials: true,
        url: `https://brainlox-python-api.herokuapp.com/python/contactme?name=${contact.name}&email=${contact.email}&message=${contact.message}` 
        }).then((res) => {
            if(res.data.success){
                alertContent()
                navigate("/");
            }
        }).catch((err) => {
        console.log(err);
        });
        setContact(INITIAL_STATE);
  }

  return (
    <div className='headern'>
        <div className='container'>
            <div className="contact-form">
                <h2>Contact Us</h2>
                <p>Your email address will not be published. Required fields are marked *</p>

                <form id="contactForm" ref={form} onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Your Name"
                                    value={contact.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Your email address"
                                    value={contact.email}
                                    onChange={handleChange}
                                />
                            </div>
                        </div> 

                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <textarea
                                    name="message"
                                    cols="30"
                                    rows="5"
                                    placeholder="Write your message..."
                                    value={contact.message}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-lg-12 col-sm-12">
                            <button type="submit" className="default-btn">Send Message</button>
                        </div>
                        <div>
                            <p>For any queries, Please knock us. Happy to help you anytime!</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default Contact
