import "./css/styles.css";
import Difficulty from './components/Difficulty'
import IdePage from './components/IdePage'
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from "./components/Footer";
import Playground from "./components/Playground";
import Signup from "./components/Signup";
import Banner from "./components/Banner";
import Contact from "./components/Contact";
import CookieConsent from "react-cookie-consent";
import ChatTutor from "./chatTutor/index";

export default function App() {

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Login Route is the first page*/}
          {/* <Route path='/' element={<Login />} /> */}
          <Route path='/signup' element={<Signup/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/chatTutor" element={<ChatTutor/>} />
          {/* Problems Route */}
          <Route
            path='/'
            element={
              <div className="origin">
                <CookieConsent location="bottom" 
                style={{}}
                debug={true}
                >This website uses cookies to enhance the user experience.</CookieConsent>
                <Navbar />
                <Banner/>
                <Difficulty ptype="problems" />
                
                <Footer />
                
              </div>
            }   
          />

          {/* Workshop Route */}
          <Route
            path='/workshops'
            element={
              <>
                <Navbar />
                <Banner/>
                <Difficulty ptype="workshops" />
                <Footer />
                
              </>
            }   
          />

          {/* IDE Page */}
          <Route 
            path="/idePage"
            element={
              <>
                <Navbar />
                <Banner/>
                <IdePage />
                <Footer />
              </>
            }
          />

          {/* Playground page */}
          <Route
            path='/playground'
            element={
              <>
                <Navbar />
                <Banner/>
                <Playground />
               
                <Footer />
              </>
            }
          />

        </Routes>
        
      </div>
    </Router>
    
  );
}
