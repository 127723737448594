import React from 'react';
import "../css/section.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";


export default function Section({title,num,type}) {
  let email='';
  let user='';
  if(localStorage.getItem("Email")){
    email=localStorage.getItem("Email")
    user = email.substring(0,localStorage.getItem("Email").indexOf('@'));
  }
  return (
    <div className='section'>
        <Swiper
        cssMode={true}
        mousewheel={true}
        keyboard={true}
        autoplay={ {delay:5000}}
        modules={[ Pagination, Mousewheel, Keyboard, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className='captain'>
            <div className='caption'>
              <h2>
                <span class="material-symbols-outlined">contacts</span>
                {localStorage.getItem("Email")!=null?" Welcome "+user : "Welcome coder"}
              </h2>
              <h3>{type==='prob'?"select any practice set to start":""}</h3>
              <h4 className='head-title'>
                {title}
              </h4>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide><img src='/images/banner.png' className='imgbanner' alt=""/></SwiperSlide>
      </Swiper>
    </div>
  );
}
