import React, {useState, useEffect} from 'react';
import './GetAnswer.css';
import {Configuration, OpenAIApi} from 'openai';

const configuration = new Configuration ({
  apiKey: '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',
});
const openai = new OpenAIApi (configuration);

const GetAnswer = props => {
  const [response, setResponse] = useState (null);
  const [apiCalled, setApiCalled] = useState (false);

  const callOpenAIapi = async () => {
    if((response === null) && (apiCalled === false)){
      setApiCalled(true);
      let completionData = await openai.createCompletion ({
        model: 'text-davinci-003',
        prompt: `in perspective of one who is ${props.userTypeSelected} explain ${props.topicSelected} in ${props.languageSelected} in a ${props.userTone} along with code example`,
        max_tokens: 1024,
        n: 1,
        temperature: 0.5,
      });
      setResponse (completionData.data.choices[0].text);
      props.actionProvider.restartChatRequest();
    }
  }

  useEffect (() => {
    callOpenAIapi ();
  },[]);

  return (
    <div>
      {response
        ? <div className="options-container">{response}</div>
        : <p>{`Loading...`}</p>}
    </div>
  );
};

export default GetAnswer;
