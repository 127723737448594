import React from "react";
import "./RestartChat.css";

const RestartChat = (props) => {
  const options = [
    { 
      text: "Restart Chat",
      handler: props.actionProvider.greet,
      id: 1 
    },
    { 
      text: "Select a new topic",
      handler: props.actionProvider.handleSelectUserType,
      id: 2
    },
  ];

  const buttonsMarkup = options.map((option) => (
    <button 
      key={option.id} 
      onClick={option.handler}
      className="option-button"
    >
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default RestartChat;
