import React from "react";

import "./SelectLanguage.css";

const SelectLanguage = (props) => {
  const options = [
    {
      text: "Python",
      id: 1
    },
    {
      text: "Java",
      id: 2
    },
    {
      text: "Javascript",
      id: 3,
    },
    {
      text: "Other",
      id: 4,
    },
  ];

  const buttonsMarkup = options.map((option) => (
    <button
      key={option.id}
      onClick={() => {
        if (option.id !== 4) {
          props.setState(prevState => ({
            ...prevState,
            languageSelected: option.text,
          }));
          props.actionProvider.handleSelectLanguage()
        }
        else {  
          props.actionProvider.handleOtherLanguage()
        }
      }}
      className="option-button"
    >
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default SelectLanguage;
