import React from 'react'
import "../css/learder.css";

const Leaderboard = () => {
  return (
    <div className='box-c'>
      <div className='text-box card-pad l-section'>
        <div className='crd-in'>
          <p className='card-name'>Users</p>
          <p className='card-name'>Ratings</p>
        </div>
        <div className='perfomer'>
          <div className='peformer'>
            <div className='performer plastic high'>
              <p className='performer'>1. alex</p>
              <p className='pt-rank'>1234</p>
            </div>
            <div className='performer plastic '>
              <p className='performer'>2. alex</p>
              <p className='pt-rank'>1234</p>
            </div>
            <div className='performer plastic '>
              <p className='performer'>3. alex</p>
              <p className='pt-rank'>1234</p>
            </div>
            <div className='performer plastic'>
              <p className='performer'>4. alex</p>
              <p className='pt-rank'>1234</p>
            </div>
            <div className='performer plastic'>
              <p className='performer'>5. alex</p>
              <p className='pt-rank'>1234</p>
            </div>
            <hr/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Leaderboard