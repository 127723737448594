import React from "react";
import "./SelectTopic.css";

const SelectTopic = (props) => {
  const options = [
    { 
      text: "Strings, Lists and Tuples",
      id: 1 
    },
    { 
      text: "Dictionaries and Sets",
      id: 2
    },
    {
      text: "Conditional Execution",
      id: 3
    },
    { 
      text: "Loops",
      id: 4
    },
    { 
      text: "Comprehensions",
      id: 5
    },
    {
      text: "Functions",
      id: 6
    },
    { 
      text: "Modules",
      id: 7 
    },
    { 
      text: "Scopes and Namespaces",
      id: 8
    },
    {
      text: "File Handling",
      id: 9
    },
    { 
      text: "Object Oriented Programming",
      id: 10 
    },
    { 
      text: "Iterator, Generator, Decorators",
      id: 11
    },
  ];

  const buttonsMarkup = options.map((option) => (
    <button 
      key={option.id} 
      onClick={ () => {
        props.setState (prevState => ({
          ...prevState,
          topicSelected: option.text,
        }));
        props.actionProvider.handleSelectUserTopic()}
      }
      className="option-button"
    >
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default SelectTopic;
