import React from 'react'
import "../css/signup.css"
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import amplitude from "amplitude-js"

const promo_code=require("../promos_data.json").codes;
const Signup = () => {
    const navigate = useNavigate();
    const [email, setemail] = useState('');
    const [promo, setpromo] = useState('');
    const pass=true;
    const fail=false;
    // const [open, setopen] = useState(false);
    const logme=()=>{
      localStorage.setItem("Email",email);
    }
    const data=async (e)=>{
      logme()
      e.preventDefault();
      log()
      const res=await axios.get(`https://brainlox-python-api.herokuapp.com/python/getotp?email=${email}`)
      localStorage.setItem("OTP",res.data.otp)
      if(promo_code.includes(promo)){
        console.log(promo);
        localStorage.setItem("ispromo",pass);
      }else{
        localStorage.setItem("ispromo",fail);
      }
      amplitude.getInstance.logEvent("user has given email id");
      amplitude.getInstance.logEvent("Requested for otp");
    }
    console.log(localStorage.getItem("ispromo"));
    const log=()=>{
      // e.preventDefault();
      Swal.fire({
        title: "E-mail verification",
        html: `<span>Enter the code we just send on your E-mail <span class="mai">${email}</span></span>`,
        input: 'text',
        text:`Did'nt receive code?`,
        showCancelButton: false  , 
        confirmButtonColor: 'green' ,
        confirmButtonText:'Validate' ,
        buttons:'Resend',
    }).then((result) => {
      if (result.value) {
        localStorage.setItem("otps", result.value)
        console.log(result.value + " " + localStorage.getItem("OTP"));
        verifydata()
      }
    });
    // e.preventDefault();
  }
  const verifydata = async () => {
    const res = await axios.get(`https://brainlox-python-api.herokuapp.com/python/verifyotp?email=${localStorage.getItem("Email")}&otp=${localStorage.getItem("otps")}`)
    if (res.data.success) {
      navigate("/")
      amplitude.getInstance.logEvent("user has validated otp");
    } else {
      alert("Invalid OTP Retry!!")
      log()
    }
  }

  // const isUser = async () =>{  here after api just update it
  //   const res=await axios.get()
  //   if(res.data){
  //     navigate('/problems');
  //   }else{
  //     navigate('/Usercard');
  //   }
  // }

  return (
    <>
      <div class="parent clearfix">
        <div class="bg-illustration">
        </div>
        <div class="login">
          <div class="container">
            <h1>Login to access to<br />your account</h1>
            <div class="login-form">
              <form className='e-form' onSubmit={data}>
                <label className='labels'>Adult Email</label>
                <input required type="email" placeholder="E-mail Address" onChange={(e) => {
                  setemail(e.target.value)
                }} />
                <input type="text" placeholder="Optional PromoCode" onChange={(e) => {
                  setpromo(e.target.value)
                }} />
                <button className='otp-button' type='submit'>Send OTP</button>
                <div className='privacy'>
                  <input type="checkbox" required className='tc'/>
                  <h6 className='policy'>
                    <p>Want to receive personalised recommendations and special offers? Opt out anytime.
                    <span> I certify that I am 13 + years of age.</span> 
                    </p>
                                   
                  </h6>
                </div>
                <p>By clicking, you agree to the <a href="https://brainlox.com/terms-of-service" target="_blank" rel="noreferrer noopener">Terms &amp; Conditions</a> and <a href="https://brainlox.com/privacy-policy" target="_blank" rel="noreferrer noopener">Privacy Policy</a>.</p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Signup